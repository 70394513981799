const lista = [
    {
        nombre:"INICIO", 
        url:"/"
    },{
        nombre:"SOCIOS", 
        url:"/socios"
    },{
        nombre:"INFORMES", 
        url:"/"
    },{
        nombre:"EVENTOS", 
        url:"/"
    }
    ,{
        nombre:"REUNIONES", 
        url:"/"
    }
    ,{
        nombre:"DOCUMENTOS", 
        url:"/"
    }
    ,{
        nombre:"ESPACIOS COLABORATIVOS", 
        url:"/"
    },{
        nombre:"SINGLE SIGN ON", 
        url:"/"
    }]
    ;
export default lista;