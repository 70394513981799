import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './modal.css';
import axios from "axios";


function CrearSocioModal({tiposDeSocio, areasDeSocio, mostrarModal, cerrarModal, submitModal}) {
  const socioEstruct = {
    denominacion: "",
    tipoSocio: null,
    areas:[],
    cuit:"",
    descripcion: "",
    mail: "",
    telefono: "",
    sitioWeb: "",
    ubicacion:{
        provincia: "",
        localidad: "",
        calle: "",
        altura: "",
        departamento: "",
        piso: "" 
    }
  };


  return (
  <React.StrictMode>
    <div className={`modal modal-transparente ${mostrarModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: mostrarModal ? 'block' : 'none' }}>
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Crear Nuevo Socio</h5>
            <button type="button" onClick={cerrarModal}  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          
          </div>
          <div className="modal-footer">
            {/* 
            <button type="button" className="btn btn-secondary" onClick={cerrarModal}>Cerrar</button>
            <button type="button" className="btn btn-primary">Guardar Cambios</button>
            */}
          </div>
        </div>
      </div>
    </div>
  </React.StrictMode>
  );
}
/*
    <div class={`modal ${mostrarModal ? 'show' : ''} fade`} id="crearSocioModal" tabindex="-1" aria-labelledby="crearSocioModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="crearSocioModalLabel">Crear Nuevo Socio</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cerrar" onClick={cerrarModal}></button>
          </div>
          <div class="modal-body ">
            <FormularioCrear tipoSocio={tipoSocio} areas={areas} />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={cerrarModal}>Cerrar</button>
            <button type="button" class="btn btn-primary" onClick={submitModal}>Crear Socio</button>
          </div>
        </div>
      </div>
    </div>
*/
export default CrearSocioModal;
