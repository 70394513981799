import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import './App.css'; // Archivo CSS personalizado para agregar estilos adicionales
import './conteiner.css'; // Archivo CSS personalizado para agregar estilos adicionales
//import fondo from './images/fondo.png';

function pathjoin(rutaInicial, otraRuta){
  if (rutaInicial.endsWith("/") && otraRuta.startsWith("/")) {
    otraRuta = otraRuta.substring(1); // Elimina el "/" inicial de otraRuta
  }
  
  return `${rutaInicial}${otraRuta}`;
}

function Conteiner(props) {
  //url(http://localhost:3000/static/media/fondo.dcdde3981612ecfca5a8.png)
  const { backgroundImage, children } = props;
  let containerStyle = "col-12 justify-content-center custom-container"

  if(backgroundImage==='si'){
      containerStyle += " custom-fondo";
      console.log(containerStyle);
  }
  
  return (
    <div className="container-fluid h-100 ">
      <div className="row h-100">
        <div className={containerStyle}>
          {/* Elemento interno con la clase elemento-interno */}
          <div className="elemento-interno">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Conteiner;
