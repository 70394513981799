import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Usuario from './usuario';
import Logo from './logo';
import '../header/header.css';


function Header(){
  return (
    <header className="container-fluid">
      <div className="row">
        {/* Logo a la izquierda */}
        <div className="col-6">
          <Logo/>
        </div>
        <div className="col-6">
          <Usuario nombre='EMPRESA SOCIA COPAL'/>
        </div>

        {/* Zona de inicio de sesión a la derecha (2 columnas) */}
        
          
        
      </div>
    </header>
  );
}

export default Header;
