import 'bootstrap/dist/css/bootstrap.min.css';  
import './navhorizontal.css';

const NavHorizontal=(props)=>{
    return(
        <div className="container-fluid  mb-0   ">
        {/*<!-- Primera fila con una columna de 12 -->*/}
        <div className="row ">
            <div class="col-12 mb-0  ">
                {/*<!-- Contenido de la primera fila aquí -->*/}
                <p className='socio'>{props.ruta}</p>
            </div>
        </div>
    </div>
    );
};
export default NavHorizontal;