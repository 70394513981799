import React, { useState } from 'react';
import Lateral from "../../components/lateral/lateral";
import Header from "../../components/header/header";
import Footer from '../../components/footer';
import Conteiner2 from '../../components/conteiners/conteiner210';
//import TarjetaUsuario from '../GestionSocios/components/tarjetausuario';
import Conteiner from '../../components/conteiners/conteiner100';
import Navegacion from '../../components/navhorizontal/navhorizontal';
import GlobalConteiner from '../../components/conteiners/globalConteiner';
//import Formulario from '../GestionSocios/components/formulario';
//import UserCard from '../GestionSocios/components/formularioVer';
import TarjetaUsuario from '../GestionSocios/components/tarjetausuario';
import CrearSocioModal from '../GestionSocios/modal/modalCrear';
import respuestaGet from './ejemplo';
import Filtrado from '../../components/navhorizontal/filtro';
import VerSocioModal from '../GestionSocios/modal/modalVer';
import EditarSocioModal from '../GestionSocios/modal/modalEditar';
import EliminarSocioModal from '../GestionSocios/modal/modalEliminar';
import ContainerCards from '../../components/conteiners/conatainerCards';
import { SociosGet } from '../GestionSocios/service/socioServicePostData';

let socio = respuestaGet[0];
const tipoSocios = [
  { id: 0, nombreTipoSocio: 'Ninguno'},
  { id: 1, nombreTipoSocio: 'Empresa' },
  { id: 2, nombreTipoSocio: 'Cámara' }
];

const areas = [
  {"id": 1,"nombre": "Normativa"},
  {"id": 2, "nombre": "RRII"},
  {"id": 3,"nombre": "Ambiente"},
  {"id": 4,"nombre": "Comité  Ejecutivo"},
  {"id": 5,"nombre": "Comisión Directiva"},
  {"id": 6,"nombre": "President"},
  {"id": 7,"nombre": "Gerentes"},
  {"id": 8,"nombre": "Comex"},
  {"id": 9,"nombre": "Pyme"},
  {"id": 10,"nombre": "Fiscal"},
  {"id": 11,"nombre": "Laborales"},
  {"id": 12,"nombre": "Legales"},
  {"id": 13,"nombre": "Envases"}
];

function PruebasA(){
  /*Mostar Crear Socio logica*/
  const [mostrarModal, setMostrarModal] = useState(false);
  const abrirModal = () => {setMostrarModal(true);};
  const cerrarModal = () => {setMostrarModal(false);};
  /*Fin Mostrar Socio seleccionado Logica*/

  /*Mostrar Ver socio logica*/
  const [verSocio, setVerSocio] = useState(false);
  const abrirModalVer= ()=>{setVerSocio(true);};
  const cerrarModalVer = ()=>{setVerSocio(false);};
  /*fin Ver socio seleccionado */

  /*Editar socio modal logica */
  const [editarSocio, setEditarSocio] = useState(false);
  const abrirModalEditar=()=>{setEditarSocio(true);};
  const cerrarModalEditar=()=>{setEditarSocio(false);};
  /*fin editar socio*/

  /*Eliminar socio modal logica*/
  const [eliminarSocio, setEliminarSocio] = useState(false);
  const abrirModalEliminar=()=>{setEliminarSocio(true);};
  const cerrarModalEliminar=()=>{setEliminarSocio(false);};
  /*fin eliminar socio modal */

  /* Peticion get para los socios */
 /* const {socio, areas, tipoSocios, provincias} = CrearSocioGet();
  const {respuestaGet, paginas} = SociosGet();
*/
  /*Logica seleccion del socio*/
  const [socioSeleccionado, setSocioSeleccionado] = useState(socio);



  const verSocioSeleccionado = (index)=>{
    index = parseInt(index);
    setSocioSeleccionado(respuestaGet[index]);
    console.log(`Se asigno para verel index: ${index}`)
    abrirModalVer();
  };

  const editarSocioSeleccionado = (index)=>{
    index = parseInt(index);
    setSocioSeleccionado(respuestaGet[index]);
    console.log(`Se asigno para editar el index: ${index}`)
    abrirModalEditar();
  };
  
  const eliminarSocioSeleccionado = (index)=>{
    index = parseInt(index);
    setSocioSeleccionado(respuestaGet[index]);
    console.log(`Se asigno para eliminar el index: ${index}`)
    abrirModalEliminar();
  };
  const socios = respuestaGet.map((socio, index) => (
    <TarjetaUsuario key={index} id={socio.id} index={index} descripcion={socio.descripcion} nombre={socio.denominacion} funcionVer={verSocioSeleccionado}
    funcionEditar={editarSocioSeleccionado} funcionEliminar={eliminarSocioSeleccionado}/>
  ));

  

  console.log(SociosGet(1));
  return (
    <GlobalConteiner >
        <Header/>
        
        <Conteiner2>
          <Lateral/>
          <Conteiner>
            <Navegacion ruta={"RUTA DE PRUEBA"}/>
            <Filtrado handleEvent={abrirModal}/>
            
            <ContainerCards>
              {socios.slice(0,4)}
            </ContainerCards>
            <CrearSocioModal tiposDeSocio={tipoSocios} areasDeSocio={areas} mostrarModal={mostrarModal} cerrarModal={cerrarModal} />
            <VerSocioModal user={socioSeleccionado} mostrarModalVer={verSocio} cerrarModalVer={cerrarModalVer}/>
            <EditarSocioModal socio={socioSeleccionado} tiposDeSocio={tipoSocios} areasDeSocio={areas} mostrarModal={editarSocio} cerrarModal={cerrarModalEditar}/>
            <EliminarSocioModal socio={socioSeleccionado}  mostrarModal={eliminarSocio} cerrarModal={cerrarModalEliminar} />
          </Conteiner> 
           
        </Conteiner2>
        
        <Footer/>

    </GlobalConteiner>

  );

}

export default PruebasA;