import 'bootstrap/dist/css/bootstrap.min.css';
import  './lateral.css';

function EnlaceItem(props){
    return(
        <a id="bloque" className="d-block  " href={props.url} >{props.nombre}</a>
    );
}

const Item=(props)=>{
    let items =[];

    props.items.forEach(item => {
        items.push(<EnlaceItem url = {item.url} nombre={item.nombre}/>);
    }); 
        
      
    return(
        <div className='menu'>
            {items}
        </div>
);

}
export default Item;