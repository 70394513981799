import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './conteiner.css'; // Archivo CSS personalizado para agregar estilos adicionales



function ContainerCards({children}) {

  return (
    <div className="container-fluid h-100 ">
      <div className="row h-100">
        <div className='diplay-cards'>
            {children}
        </div>
      </div>
    </div>
  );
}

export default ContainerCards;