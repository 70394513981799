import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
function Paginacion({ paginaActual, totalPaginas, onChangePagina }) {
  const paginas = [];
  for (let pagina = 0; pagina < totalPaginas; pagina++) {
    paginas.push(
      <Pagination.Item
        key={pagina}
        active={pagina === paginaActual}
        onClick={() => onChangePagina(pagina)}
      >
        {pagina}
      </Pagination.Item>
    );
  }

  return (
    <div>
      <Pagination>{paginas}</Pagination>
    </div>
  );
}

export default Paginacion;
