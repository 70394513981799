import axios from "axios";
import React, {useState} from "react";
import Lateral from "../../components/lateral/lateral";
import Header from "../../components/header/header";
import Footer from '../../components/footer';
import Conteiner2 from '../../components/conteiners/conteiner210';
import Conteiner from '../../components/conteiners/conteiner100';
import Navegacion from '../../components/navhorizontal/navhorizontal';
import GlobalConteiner from '../../components/conteiners/globalConteiner';
import TarjetaUsuario from '../GestionSocios/components/tarjetausuario';
import CrearSocioModal from '../GestionSocios/modal/modalCrear';
import respuestaGet from './ejemplo';
import Filtrado from '../../components/navhorizontal/filtro';
import VerSocioModal from '../GestionSocios/modal/modalVer';
import EditarSocioModal from '../GestionSocios/modal/modalEditar';
import EliminarSocioModal from '../GestionSocios/modal/modalEliminar';
import ContainerCards from '../../components/conteiners/conatainerCards';
import Paginacion from '../../components/paginacion';

const socioDefault = {
  "id": 0,
  "denominacion": "",
  "descripcion": "",
  "mail": "",
  "telefono": "",
  "ubicacion": {
      "calle": "",
      "altura": "",
      "piso": "",
      "departamento": "",
      "localidad": "",
      "provincia": ""
  },
  "sitioWeb": "",
  "fechaDeAlta": "",
  "tipoSocio": "",
  "areas": []
};

const tipoSocios = [
  { id: 0, nombreTipoSocio: 'Ninguno'},
  { id: 1, nombreTipoSocio: 'Empresa' },
  { id: 2, nombreTipoSocio: 'Cámara' }
];

const areas = [
  {"id": 1,"nombre": "Normativa"},
  {"id": 2, "nombre": "RRII"},
  {"id": 3,"nombre": "Ambiente"},
  {"id": 4,"nombre": "Comité  Ejecutivo"},
  {"id": 5,"nombre": "Comisión Directiva"},
  {"id": 6,"nombre": "President"},
  {"id": 7,"nombre": "Gerentes"},
  {"id": 8,"nombre": "Comex"},
  {"id": 9,"nombre": "Pyme"},
  {"id": 10,"nombre": "Fiscal"},
  {"id": 11,"nombre": "Laborales"},
  {"id": 12,"nombre": "Legales"},
  {"id": 13,"nombre": "Envases"}
];



export default function App() {
  /*ZONA DE PAGINACION */
  const [paginaActual, setPaginaActual] = useState(0);
  const [paginas, setPaginas] = React.useState(0);

  const handleChangePagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
    // Aquí puedes realizar alguna acción cuando se cambia de página
    //console.log('Se actualizo a pagina: ', nuevaPagina);
  };
  
  /*API LLAMADAS */
  //Variables de estado
  const [sociosApi, setSociosApi] = React.useState(null);

  const ObtenerSociosAPI = () => {
    React.useEffect(() => {
      const baseURL = `http://localhost:8080/socios?page=${paginaActual}`;
      axios.get(baseURL).then((response) => {
        setSociosApi(response.data.content);
        setPaginas(response.data.totalPages);
      });
    }, [paginaActual]);   
    //console.log("Se obtuvieron los socios Pagina: ", paginaActual); 
  }

ObtenerSociosAPI();

  /* FIN API LLAMADAS */

   /*Mostar Crear Socio logica*/
   const [mostrarModal, setMostrarModal] = useState(false);
   const abrirModal = () => {setMostrarModal(true);};
   const cerrarModal = () => {setMostrarModal(false);};
   /*Fin Mostrar Socio seleccionado Logica*/
 
   /*Mostrar Ver socio logica*/
   const [verSocio, setVerSocio] = useState(false);
   const abrirModalVer= ()=>{setVerSocio(true);};
   const cerrarModalVer = ()=>{setVerSocio(false);};
   /*fin Ver socio seleccionado */
 
   /*Editar socio modal logica */
   const [editarSocio, setEditarSocio] = useState(false);
   const abrirModalEditar=()=>{setEditarSocio(true);};
   const cerrarModalEditar=()=>{setEditarSocio(false);};
   /*fin editar socio*/
 
   /*Eliminar socio modal logica*/
   const [eliminarSocio, setEliminarSocio] = useState(false);
   const abrirModalEliminar=()=>{setEliminarSocio(true);};
   const cerrarModalEliminar=()=>{setEliminarSocio(false);};
   /*fin eliminar socio modal */
 
   /*Logica seleccion del socio*/
   const [socioSeleccionado, setSocioSeleccionado] = useState(socioDefault);
 
   const verSocioSeleccionado = (index)=>{
     index = parseInt(index);
     setSocioSeleccionado(respuestaGet[index]);
     console.log(`Se asigno para verel index: ${index}`)
     abrirModalVer();
   };
 
   const editarSocioSeleccionado = (index)=>{
     index = parseInt(index);
     setSocioSeleccionado(respuestaGet[index]);
     console.log(`Se asigno para editar el index: ${index}`)
     abrirModalEditar();
   };
   
   const eliminarSocioSeleccionado = (index)=>{
     index = parseInt(index);
     setSocioSeleccionado(respuestaGet[index]);
     console.log(`Se asigno para eliminar el index: ${index}`)
     abrirModalEliminar();
   };

   //Evito que renderize algo que no existe
   if (!sociosApi) return null;//<<<-----
   

  const modalCrear = (<CrearSocioModal tiposDeSocio={tipoSocios} areasDeSocio={areas} mostrarModal={mostrarModal} cerrarModal={cerrarModal} />);
  const modalVer = (<VerSocioModal user={socioSeleccionado} mostrarModalVer={verSocio} cerrarModalVer={cerrarModalVer}/>);
  const modalEditar = (<EditarSocioModal socio={socioSeleccionado} tiposDeSocio={tipoSocios} areasDeSocio={areas} mostrarModal={editarSocio} cerrarModal={cerrarModalEditar}/>);
  const modalEliminar = (<EliminarSocioModal socio={socioSeleccionado}  mostrarModal={eliminarSocio} cerrarModal={cerrarModalEliminar}/>);
  
  let sociosCards = sociosApi.map((socio, index) => (
    <TarjetaUsuario key={index} id={socio.id} index={index} descripcion={socio.descripcion} 
      nombre={socio.denominacion} funcionVer={verSocioSeleccionado}
      funcionEditar={editarSocioSeleccionado} funcionEliminar={eliminarSocioSeleccionado}
    />));
  //console.log('Se renderearon los socios');
  return (
    <React.StrictMode>
        <GlobalConteiner >
          <Header/>
          <Conteiner2>
            <Lateral/>
            <Conteiner>
              <Navegacion ruta={"RUTA DE PRUEBA"}/>
              <Filtrado handleEvent={abrirModal}/>
              <ContainerCards> 
                {sociosCards}
              </ContainerCards>
              <Paginacion
                    paginaActual={paginaActual}
                    totalPaginas={paginas}
                    onChangePagina={handleChangePagina}
                  />
              {modalCrear}
              {modalVer}
              {modalEditar}
              {modalEliminar}   
            </Conteiner> 
          </Conteiner2>
          <Footer/>
      </GlobalConteiner>
    </React.StrictMode>
  );
}