import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tarjetausuario.css'; // Archivo CSS personalizado para agregar estilos adicionales
import logosocio from '../../../assets/images/logo_socio.png';
import ver from './icons/view3.png';
import editar from './icons/edit3.png';
import borrar from './icons/trash3.png';

function Icono({src, nombre, clase, funcion}) {
  return (
    <React.StrictMode>
      {/* Icono centrado */}
        <div className="container-img-icon" onClick={funcion}>
          <img src={src} alt={nombre} className={clase} />
        </div>
          
        
    </React.StrictMode>
  );
}


function LogoSocio({url}){
  return (
    <div className="container-logo">
      <div className="logo">
        {/* Imagen centrada */}
        <img src={url} alt="Imagen centrada" className="img_logo " />
      </div>
      
    </div>
  );
}
function Denominacion({denominacion}){
  return(
    <div>
      {/* Nombrede la empresa */}
      <label className='denominacion'>{denominacion}</label>
    </div>
  );
}

function Descripcion({descripcion}){
  //let descripcion = "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...";
  return (
    <div >
      {/* Texto centrado */}
      <p className="descripcion">{descripcion}</p>
    </div>
  );
}

function Acciones({id, funcionVer, funcionEditar, funcionEliminar}){
  
  return (
      <div className="container-actions">
      {/* Icconos centrado */}
        <div className="container-action">
            <div className="container-icons">
              <Icono src={ver}  nombre='icono_ver' clase='custom-icon' funcion={funcionVer}  />
              <Icono src={editar}  nombre='icono_editar' clase='custom-icon-edit' funcion={funcionEditar}/>
              <Icono src={borrar}  nombre='icono_borrar' clase='custom-icon-delete' funcion={funcionEliminar}/>
            </div>
          </div>
      </div>
  );
}

function TarjetaUsuario({id,nombre, descripcion, index, funcionVer, funcionEditar, funcionEliminar, imagenUrl}) {
  return (
  <div className='container-global'>
    <div className="container-child">
      <div className="zona0">
          {/* Zona 0: Imagen */}
            <LogoSocio url={imagenUrl} />
      </div>
    </div>
    <div className='container-child'>
        {/* Zona 1: Denominacion / Nombre de la empresa */}
        <div className='zona1'>
          <Denominacion denominacion={nombre}/>
        </div>
        {/* Zona 2: Descripción */}
        <div className="zona2">
          <Descripcion descripcion = {descripcion}/>
        </div>
        {/* Zona 3: Acciones de la card */}
        <div className='zona3'>
          <Acciones id={id} funcionVer={()=>funcionVer(index)} funcionEditar={()=>funcionEditar(index)} funcionEliminar={()=>funcionEliminar(index)}/>
        </div>      
      </div>
  </div>
  );
}

export default TarjetaUsuario;
