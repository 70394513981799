import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import User from '../../assets/icons/user.png';
import Logout from '../../assets/icons/logout.png';
import './header.css';

function Usuario(props) {

  return (
    <div className="containerUser">
      <div className="cantainer-user">
        <div className="user-name">
          <span className='text-user'>{props.nombre}</span>
        </div>
        <div className="logo-user">
            <img
              src={User}
              alt="Logo de Usuario"
              className="custom-image"
            />
        </div>
        <div className="logo-logout">
            <img
                src={Logout}
                alt="Logo de Usuario"
                className="custom-image"
              />
        </div>
      </div>      
    </div>

  );
}

export default Usuario;
