import axios from 'axios';
import { useState, useEffect } from 'react';
const API_URL = "http://localhost:8080/socios";



export const obtenerDatosDesdeAPI = async (pagina=1) => {
    try {
      const response = await axios.get(`${API_URL}?page={pagina}`);
      return response.data;
    } catch (error) {
      throw error;
    }
};
  

export function SociosGet(pagina = 0){
   let responseApi = {};

   responseApi = axios.get(`http://localhost:8080/socios?page=${pagina}`)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          //console.error('Error al obtener datos:', error);
          return error;
        });
    
  
    return responseApi;
}