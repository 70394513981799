import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './modal.css';
import FormularioCrear from '../components/formularioAgregar';

function CrearSocioModal({socio, tiposDeSocio, areasDeSocio, mostrarModal, cerrarModal, submitModal}) {
  console.log(socio.id);
  return (
  <React.StrictMode>
    <div className={`modal modal-transparente ${mostrarModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: mostrarModal ? 'block' : 'none' }}>
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Crear Nuevo Socio</h5>
            <button type="button" onClick={cerrarModal}  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <FormularioCrear socioEstruct={socio} tiposDeSocio={tiposDeSocio} areasDeSocio={areasDeSocio} />
          </div>
          <div className="modal-footer">
            {/* 
            <button type="button" className="btn btn-secondary" onClick={cerrarModal}>Cerrar</button>
            <button type="button" className="btn btn-primary">Guardar Cambios</button>
            */}
          </div>
        </div>
      </div>
    </div>
  </React.StrictMode>
  );
}

export default CrearSocioModal;