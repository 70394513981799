import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./modules/Home/home";
import GestionSocios from './modules/GestionSocios/gestionsocios';
import PruebasA from './modules/PruebasA/pruebas';
import PruebasE from './modules/PruebasE/pruebasE';
import API from './modules/PruebasE/pruebaApi';
import NuevoSocio from "./modules/GestionSocios/nuevoSocio";
import EditarSocio from "./modules/GestionSocios/editarSocio";

export default function Rutas() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/socios" element={<GestionSocios />} />
          <Route path="/PruebasA" element={<PruebasA />} />
          <Route path="/PruebasE" element={<PruebasE />} />
          <Route path="/api" element={<API/>} />
          <Route path="/socios/nuevo" element={<NuevoSocio />} />
          <Route path='/socios/:id/editar' element={<EditarSocio />} />
      </Routes>
    </BrowserRouter>
  );
}