/*{   "id": 0,
        "denominacion": "Denominacion",
        "descripcion": "Mi empresa es Socio1",
        "tipoSocio": {
            "id": 1,
            "nombreTipoSocio": "EMPRESA"
        },
        "mail": "Socio1@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": '',
            "departamento": '',
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio1.com.arg",
        "areas": [{ "id": 1, "nombre": "Normativa"},{ "id": 2, "nombre": "RRII"}],
        "cuit": "20605987415"
    },
*/
const respuestaGet = [
    {
        "id": 0,
        "denominacion": "Denominacion",
        "descripcion": "Mi empresa es Socio0",
        "mail": "Socio0@gmail.com",
        "telefono": "12345678",
        "ubicacion": {
            "calle": "falsa",
            "altura": "1234",
            "piso": "",
            "departamento": "",
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": [{ "id": 1, "nombre": "Normativa"},{ "id": 2, "nombre": "RRII"}]
    },
    {
        "id": 1,
        "denominacion": "Socio1",
        "descripcion": "Mi empresa es Socio1",
        "mail": "Socio1@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio1.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 2,
        "denominacion": "Socio2",
        "descripcion": "Mi empresa es Socio2",
        "mail": "Socio2@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio2.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 3,
        "denominacion": "Socio3",
        "descripcion": "Mi empresa es Socio3",
        "mail": "Socio3@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio3.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 4,
        "denominacion": "Socio4",
        "descripcion": "Mi empresa es Socio4",
        "mail": "Socio4@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio4.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 5,
        "denominacion": "Socio5",
        "descripcion": "Mi empresa es Socio5",
        "mail": "Socio5@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio5.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 6,
        "denominacion": "Socio6",
        "descripcion": "Mi empresa es Socio6",
        "mail": "Socio6@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio6.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 7,
        "denominacion": "Socio7",
        "descripcion": "Mi empresa es Socio7",
        "mail": "Socio7@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio7.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 8,
        "denominacion": "Socio8",
        "descripcion": "Mi empresa es Socio8",
        "mail": "Socio8@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio8.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 9,
        "denominacion": "Socio9",
        "descripcion": "Mi empresa es Socio9",
        "mail": "Socio9@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio9.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 10,
        "denominacion": "Socio10",
        "descripcion": "Mi empresa es Socio10",
        "mail": "Socio10@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio10.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 11,
        "denominacion": "Socio11",
        "descripcion": "Mi empresa es Socio11",
        "mail": "Socio11@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio11.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 12,
        "denominacion": "Socio12",
        "descripcion": "Mi empresa es Socio12",
        "mail": "Socio12@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio12.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 13,
        "denominacion": "Socio13",
        "descripcion": "Mi empresa es Socio13",
        "mail": "Socio13@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio13.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 14,
        "denominacion": "Socio14",
        "descripcion": "Mi empresa es Socio14",
        "mail": "Socio14@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio14.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    },
    {
        "id": 15,
        "denominacion": "Socio15",
        "descripcion": "Mi empresa es Socio15",
        "mail": "Socio15@gmail.com",
        "telefono": "1234567890",
        "ubicacion": {
            "calle": "monstruo",
            "altura": "123",
            "piso": null,
            "departamento": null,
            "localidad": "belgrano",
            "provincia": "caba"
        },
        "sitioWeb": "www.Socio15.com.arg",
        "fechaDeAlta": "2023-10-03",
        "tipoSocio":  {
            id: 1, 
            nombreTipoSocio: "EMPRESA"
        },
        "areas": []
    }
];

export default respuestaGet;