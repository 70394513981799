import 'bootstrap/dist/css/bootstrap.min.css';
import  './lateral.css';
import Item from './items';
/* lista de objetos de naveagacion */
import Lista from './itemsNavegacion';


const Lateral=()=>{
return(
    <div className="d-flex flex-column">
        <div id='sidebar-container' className="bg-primary flex-grow-1">
            <Item items={Lista} />
        </div>
    </div>

);

}
export default Lateral;